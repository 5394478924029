import React from 'react';
import { ReviewForm as ReviewFormCommon } from '../../../../../common/components/review-form/review-form';
import { useFormSettings } from '../../../common/store/configuration/use-form-settings';
import { uploadMediaFile as uploadMediaFileOriginal } from './upload-media';
import { useApi } from '../api-provider/use-api';
import { getInstance } from '../../../common/store/base-params/base-params-selectors';
import { wrapWithDevConnectionState } from '~reviews/common/wrap-with-dev-connection-state';
import {
  BaseReviewFormProps,
  CancelResult,
  ContactData,
} from '~commons/components/review-form/types';
import { TranslationKey } from '~reviews/locale-types';
import { useCancelFlow, useCancelFlowExecutor } from '~reviews/Widget/hooks/use-cancel-flow';
import { useNavigationPrompt } from '~commons/hooks/use-navigation-prompt';
import { makeReviewSelector } from '~reviews/common/services/id-utils';
import { ReviewContent } from '~commons/types';

export type ReviewFormProps = ContactData<
  Omit<
    BaseReviewFormProps<TranslationKey>,
    | 'formSettings'
    | 'onMediaUpload'
    | 'onMediaDoneUploading'
    | 'onMediaRemoved'
    | 'onLoginClick'
    | 'onBodyFocus'
    | 'onCancel'
  > & { onCancel: (content: ReviewContent, reset: () => void, isEmpty: boolean) => CancelResult }
>;

const ReviewForm: React.FC<ReviewFormProps> = ({ onCancel, id, ...props }) => {
  const formSettings = useFormSettings();

  const {
    instance,
    mediaUploadState,
    requestLogin,
    focusFormBody,
    biMediaUploaded,
    biMediaDeleted,
  } = useApi((state, actions, _h, ctx) => ({
    instance: getInstance(state),
    mediaUploadState: ctx.devToolsState.mediaUploadState,
    requestLogin: actions.requestLogin,
    focusFormBody: actions.biFocusFormBody,
    biMediaUploaded: actions.biMediaUploaded,
    biMediaDeleted: actions.biMediaDeleted,
  }));

  const uploadMediaFileFn = React.useMemo(
    () => wrapWithDevConnectionState(uploadMediaFileOriginal({ instance }), () => mediaUploadState),
    [mediaUploadState, instance],
  );

  const resolveCancelFlow = useCancelFlow({
    onCancel,
    isReply: false,
    selector: makeReviewSelector(id),
  });

  useNavigationPrompt({ resolveCancelFlow, formIsMounted: true });

  const handleCancel = useCancelFlowExecutor(resolveCancelFlow);

  return (
    <ReviewFormCommon
      {...props}
      id={id}
      formSettings={formSettings}
      onMediaUpload={uploadMediaFileFn}
      onMediaDoneUploading={(event) => biMediaUploaded(event)}
      onMediaRemoved={biMediaDeleted}
      onLoginClick={() => requestLogin()}
      onBodyFocus={() => focusFormBody()}
      onCancel={(...p) => handleCancel(...p)}
    />
  );
};

export default ReviewForm;
